import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Copy, { Bold, ListItem, UnorderedList } from '@nib-components/copy';

import Layout from '../../components/Layout';
import { ContentfulDocument } from '../../constructs/models';
import metrics from '../../metrics';

import LifeLivingInsuranceSection, {
  LifeLivingInsuranceSectionProps,
} from '../../page-sections/life-living-insurance-section';
import { ContentHeroPanelProps } from '../../components/ContentHeroPanel';
import { NavContentProps, NavLinkProps } from '../../components/NavContent';
import { ImportantInformationBannerProps } from '../../components/ImportantInformationBanner';
import Link from '@nib-components/link';
import { AddSystemIcon, ExternalLinkSystemIcon } from '@nib/icons';
import { Box, breakpoint, Section, Stack, Tiles } from '@nib/layout';
import Heading from '@nib-components/heading';
import Accordion from '@nib-components/accordion';

import WomanOnLaptopCalculatorImage from '../../img/life-and-living/woman-on-laptop-calculator.jpeg';
import CoupleHikingImage from '../../img/life-and-living/couple-hiking.jpeg';
import FamilySittingOnBenchImage from '../../img/life-and-living/family-sitting-on-bench.jpg';
import SonPiggybackingFatherImage from '../../img/life-and-living/son-piggybacking-father.jpeg';
import PoepleHoldingSurfBoardsImage from '../../img/life-and-living/people-holding-surf-boards.jpeg';

import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import { ContentBannerProps } from '../../components/ContentBanner';
import { urlConstants } from '../../constructs/constants';

const title = 'Why Life Insurance & Illness Cover is worth it';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

const contentHeroData: ContentHeroPanelProps = {
  title: (
    <>
      Let’s talk about <span style={{ color: '#82E578' }}>Life & Living insurance.</span>
    </>
  ),
  subtextLeft: 'LIFE INSURANCE',
  subtextRight: 'LIVING INSURANCE',
  description: <>It’s healthy to talk about death. It’s even better to plan for it.</>,
  imageMobile: CoupleHikingImage,
  imageDesktop: CoupleHikingImage,
  imageAlt:
    'A couple stands together on a mountain top, enjoying the breathtaking view and embracing the moment whilst hiking.',
  type: 'banner',
};

const navigationLinks: NavLinkProps[] = [
  {
    text: 'About Life & Living insurance',
    isActive: false,
    href: urlConstants.aboutLifeLivingInsurance,
  },
  {
    text: 'Special offers',
    isActive: false,
    href: urlConstants.currentOfferLifeLivingInsurance,
  },
  {
    text: 'Why get Life & Living insurance',
    isActive: true,
    href: urlConstants.whyLifeLivingInsuranceCoverIsImportant,
  },
  {
    text: 'Estimator Tool',
    isActive: false,
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
  {
    text: 'For homeowners',
    isActive: false,
    href: urlConstants.lifeLivingHomeowners,
  },
  { text: 'How to apply', isActive: false, href: urlConstants.howToApplyLifeLivingInsurance },
];

const contentData = {
  title: 'Why Life insurance & Living insurance is important',
  description: (
    <>
      Nobody knows what's coming around the corner in life. That's why we offer Life insurance &
      Living insurance, so you can prepare for the unexpected and help ensure your loved ones are
      taken care of – even if you're no longer around.{' '}
      <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>Apply online</Link> or{' '}
      <Link href={urlConstants.lifeLivingInsuranceContactUs}>speak to our team</Link> to get covered
      today.​
    </>
  ),
};

const tileData = {
  title: 'Here are three great reasons to consider Life & Living insurance:',
  primaryButton: {
    text: 'Estimate your cover',
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
  secondaryButton: { text: 'Ask a specialist', href: urlConstants.lifeLivingInsuranceContactUs },
  tiles: [
    {
      title: 'It can safeguard your biggest asset - your income',
      description:
        'If you’re unable to work due to illness, Income Protection Illness cover can help with the essential day-to-day expenses like mortgage repayments, rent or groceries — leaving you to focus on getting better.',
      image: FamilySittingOnBenchImage,
      alt: 'A family enjoying time together on a porch, accompanied by their dog, creating a warm and inviting atmosphere.',
    },
    {
      title: 'It helps give your family space to grieve',
      description:
        'If you pass away, there may be a significant financial impact on your loved ones. Life cover can help reduce stress and give those you care about time to grieve and adjust to their changing circumstances.',
      image: SonPiggybackingFatherImage,
      alt: 'A father and son enjoying a peaceful moment together while wading in a river, surrounded by nature.',
    },
    {
      title: 'It can cover your funeral',
      description:
        'Funeral expenses cover is included complimentary with any nib Life insurance or Living insurance cover you apply for online or by calling our team of specialists. It pays a lump sum payment of $15,000 if you pass away or if you get a terminal diagnosis and are expected to die within the next 12 months. This can help your loved ones meet funeral expenses without financial stress or worry.',
      image: PoepleHoldingSurfBoardsImage,
      alt: 'Two individuals stroll along the beach, each carrying a surfboard under their arms, enjoying the coastal scenery.',
    },
  ],
};

const faqData = {
  title: 'Frequently asked questions',
  description: (
    <>
      Here are some frequently asked questions about our Life insurance & Living insurance products
      that are available to purchase online or over the phone with our team of specialists. If
      you're looking for information on our nib Ultimate Life insurance & Living insurance,{' '}
      <Link href={urlConstants.aboutLifeLivingInsurance}>please click here</Link>.
    </>
  ),
  content: [
    {
      title: 'Am I eligible?',
      content: (
        <Stack space={4}>
          <Copy measure={false}>
            To apply for nib Life insurance & Living insurance online or through 0800 555 642,
            you'll need to complete an application form and be:​
          </Copy>
          <UnorderedList>
            <ListItem>
              Living in New Zealand and have one of the following:​
              <UnorderedList listType="circle">
                <ListItem>New Zealand or Australian passport/citizenship​</ListItem>
                <ListItem>
                  New Zealand or Australian Permanent Resident Visa (with no travel conditions on
                  your visa)​​
                </ListItem>
                <ListItem>
                  New Zealand Resident Visa (for Life cover and/or Serious Illness Trauma cover
                  only)​
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>18-70 years old to apply for Life Insurance (Life cover)​</ListItem>
            <ListItem>
              18-60 years old to apply for Living Insurance (Serious Illness Trauma cover or Income
              Protection Illness cover)​
            </ListItem>
            <ListItem>
              Employed for at least 25 hours per week for a single employer to apply for Living
              Insurance (Income Protection Illness cover)​
            </ListItem>
          </UnorderedList>
        </Stack>
      ),
    },
    {
      title: 'How long does the application process take?',
      content: (
        <>
          The time frame depends on your responses to the health and lifestyle questions. In some
          cases, we may be able to offer immediate cover. If further information is needed, we will
          contact you and if necessary, your doctor, directly.{' '}
          <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>Apply online</Link>{' '}
          today to get started.​
        </>
      ),
    },
    {
      title: 'How do I know how much/type of cover I need?',
      content: (
        <>
          You can use our online{' '}
          <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>Estimator Tool</Link>,
          which asks a few simple questions about your life stage to help you explore the type and
          level of cover that may suit you. Alternatively, you can{' '}
          <Link href={urlConstants.lifeLivingInsuranceContactUs}>
            speak with one of our friendly life insurance and living insurance specialists
          </Link>{' '}
          for guidance.​
        </>
      ),
    },
    {
      title: 'Will my premium go up?',
      content: (
        <Stack space={4}>
          <Bold>Your premium will usually increase each year</Bold>
          <Copy measure={false}>
            Your premium is not fixed. Even if you maintain the same level of cover, your premium
            will typically increase as the insured person gets older. If you'd like an estimate of
            how your premiums may change over time, you can ask us for an indication. We may also
            adjust premiums as outlined in the section ‘Making changes to the insurance agreement’.​
          </Copy>
          <Bold>Your cover will generally increase each year​</Bold>
          <Copy measure={false}>
            To help maintain the value of your insurance, we automatically increase your cover
            amount each year on the anniversary of your Life & Living Insurance start date, while
            you’re eligible for this indexation. This increase is based on the Consumer Price Index
            (CPI), with a minimum of 1% and a maximum of 7%.​
            <br />
            <br />
            Please note that complimentary Funeral Expenses cover does not increase and remains a
            fixed payment of $15,000.​
          </Copy>
        </Stack>
      ),
    },
    {
      title: 'Is there anything not covered?',
      content: (
        <Copy measure={false}>
          Yes. General terms, conditions, and exclusions are outlined in the{' '}
          <Link
            href={urlConstants.lifeAndLivingInsuranceWording}
            icon={ExternalLinkSystemIcon}
            target="_blank"
          >
            policy wording
          </Link>
          .​
          <br />
          <br />
          Additionally, based on your health and lifestyle, specific personal exclusions may apply.
          If applicable, these will be disclosed for your review before you decide to proceed with
          the policy.
        </Copy>
      ),
    },
  ],
};

const navigation: NavContentProps = {
  title: 'Life & Living insurance',
  homeHref: urlConstants.compareLifeAndLivingInsurance,
  primaryButton: { text: 'Apply now', href: urlConstants.estimateYourCoverageLifeLivingInsurance },
  secondaryButton: { text: 'Contact us', href: urlConstants.lifeLivingInsuranceContactUs },
  navigation: navigationLinks,
};

const importantInformationData: ImportantInformationBannerProps = {
  title: 'Important Information',
  description: [
    <Copy measure={false} size="small">
      Only the main features and benefits of the Life & Living Insurance is outlined here. For a
      full explanation of all benefits, exclusions and other terms and conditions of the cover, view
      the{' '}
      <Link href={urlConstants.lifeAndLivingInsuranceWording} icon={ExternalLinkSystemIcon}>
        Life & Living Insurance wording
      </Link>
      .
    </Copy>,
    <Copy measure={false} size="small">
      Life & Living Insurance is provided by nib nz insurance limited and is a related company of
      nib nz limited (“nib”). nib nz insurance limited issues and underwrites Life & Living
      Insurance products, and is solely responsible for claims under the cover. nib sells and may
      advise you on these products. Find out more about{' '}
      <Link href={urlConstants.financialStrength}>
        financial strength and financial advice with nib
      </Link>
      .
    </Copy>,
  ],
};

const contentBannerContactData: ContentBannerProps = {
  title: 'How to apply',
  subtitle: 'Apply',
  description: (
    <Copy measure={false}>
      Talking about death or serious illness can be tough, but applying for Life & Living Insurance
      doesn’t have to be. Whether you’re ready to take the first step with our{' '}
      <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>Estimator tool</Link> or
      would like further guidance, our specialists are here to help. You can{' '}
      <Link href={urlConstants.lifeLivingInsuranceContactUs}>contact us</Link> to speak with a
      specialist over the phone for personalised advice and support, making the process as simple
      and stress-free as possible.
    </Copy>
  ),
  image: WomanOnLaptopCalculatorImage,
  imageAlt: 'A smiling woman focused on her laptop, engaged in work with a positive demeanor.',
  primaryButtonText: 'First step: Estimate your cover',
  primaryButtonHref: urlConstants.estimateYourCoverageLifeLivingInsurance,
  secondaryButtonText: 'What do I need to apply?',
  secondaryButtonHref: urlConstants.howToApplyLifeLivingInsurance,
  flip: false,
  showAnimatedArrow: true,
  backgroundColor: 'brightGreen20',
};

const lifeLivingSectionData: LifeLivingInsuranceSectionProps = {
  heroContent: contentHeroData,
  navigation: navigation,
  importantInformationData: importantInformationData,
  bannerProps: contentBannerContactData,
};

type DocumentResult = {
  edges: {
    node: {
      document: ContentfulDocument;
    };
  }[];
};

interface ImageShape {
  file: {
    url: string;
  };
}

interface HtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
  };
}
interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  leftButtonText: string;
  rightButtonText: string;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImage: HeroImagesShape;
  importantThingsToKnow: HtmlMarkdownShape;
}

type LifeCoverOptionsPageResult = {
  edges: {
    node: NodeShape;
  }[];
};

type GraphQLResult = {
  temporaryAccidentalDeathCover: DocumentResult;
  lifePolicyDocument: DocumentResult;
  factSheetUrl: DocumentResult;
  lifeCoverOptionsPage: LifeCoverOptionsPageResult;
};

interface LifeCoverOptionsProps {
  data: GraphQLResult;
}

interface TileProps {
  title: string;
  description: string;
  image: string;
  alt: string;
}

const TileImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;

  ${breakpoint('xl')`
    height: 240px;
  `}
`;

const WhyGetLifeAndLiving = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options/why-life-living-cover-is-important',
    url: 'https://www.nib.co.nz/life-cover-options/why-life-living-cover-is-important',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Why Life Insurance & Illness Cover is worth it | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <LifeLivingInsuranceSection {...lifeLivingSectionData}>
        <MainContent />
        <FAQContent />
      </LifeLivingInsuranceSection>
    </Layout>
  );
};

const MainContent = () => {
  return (
    <Section role="section" background="trueWhite">
      <Box display="flex" flexDirection={{ xs: 'column', xxl: 'row-reverse' }} gap="4" padding="3">
        <Stack space={4}>
          <Box display="flex" justifyContent={{ xs: 'center', xl: 'start' }}>
            <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
              {contentData.title}
            </Heading>
          </Box>
          <Box display="flex" justifyContent="center">
            <Copy measure={false} color="deepBlack">
              {contentData.description}
            </Copy>
          </Box>
        </Stack>
      </Box>
      <TileContent />
    </Section>
  );
};

const TileContent = () => {
  return (
    <Box padding={3}>
      <Stack space={4}>
        <Heading color="trueGreen" component="h3">
          {tileData.title}
        </Heading>
        <Tiles space={7} columns={{ xs: 1, md: 3 }}>
          {tileData.tiles.map((tile, index) => (
            <Tile key={index} {...tile} />
          ))}
        </Tiles>
        <Box display="flex" gap={3} flexWrap="wrap">
          <SecondaryButton href={tileData.secondaryButton.href}>
            {tileData.secondaryButton.text}
          </SecondaryButton>
          <PrimaryButton href={tileData.primaryButton.href}>
            {tileData.primaryButton.text}
          </PrimaryButton>
        </Box>
      </Stack>
    </Box>
  );
};

const Tile = ({ title, description, image, alt }: TileProps) => {
  return (
    <Stack>
      <TileImage src={image} alt={alt}></TileImage>
      <Stack space={1}>
        <Heading color="deepBlack" component="h4">
          {title}
        </Heading>
        <Copy color="deepBlack">{description}</Copy>
      </Stack>
    </Stack>
  );
};

const FAQContent = () => {
  return (
    <Section role="section" background="trueWhite">
      <Stack space={4}>
        <Box display="flex" flexDirection="column" gap="4" padding="3">
          <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
            {faqData.title}
          </Heading>
          <Copy measure={false} color="deepBlack">
            {faqData.description}
          </Copy>
        </Box>
        <Box>
          <Accordion borderTop={false} borderBottom multiExpanded={false}>
            {faqData.content.map((faq, index) => (
              <Accordion.Item key={index} title={faq.title} icon={AddSystemIcon}>
                {faq.content}
              </Accordion.Item>
            ))}
          </Accordion>
        </Box>
      </Stack>
    </Section>
  );
};

export default metrics({ pageName: 'life-and-living-estimate-your-coverage' })(WhyGetLifeAndLiving);
